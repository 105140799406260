/**
 * Created by Vladyslav Dubyna 01/29/24
 *
 * this is a calculator widget component
 */

import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import Dropdown from '../dropdown/Dropdown';
import { useCalculator } from '../../hooks/useCalculator';
import { TWidgetSection } from './utils';

export interface CalculatorWidgetProps {
  sectionsData?: Array<TWidgetSection | null | any>;
  isEmpty?: boolean;
}

const CalculatorWidget: FC<CalculatorWidgetProps> = observer(({ sectionsData = [], isEmpty = false }) => {
  const { widgetData, errors } = useCalculator();

  /**
   * render widget section
   */
  const dataToRender = (widgetData || sectionsData).map((sectionData, index, self) =>
    sectionData ? (
      <div className="calculator-content-section" key={`${sectionData.title}-${index + 1}`}>
        <h3>{sectionData.title}</h3>
        {sectionData.description && <p className="description">{sectionData.description}</p>}
        {sectionData.descriptionData}
        {sectionData.dropdownData?.map((data, i) => (
          <Dropdown summaryTitle={data.dropdownTitle} key={`${data.dropdownTitle}-${i + 1}`} defaultOpen={data.defaultOpen}>
            {data.dropdownContent}
          </Dropdown>
        ))}
        {index < self.length - 1 && <div className="calculator-divider" />}
      </div>
    ) : null);

  /**
   * render errors
   */
  const errorsToRender = () =>
    errors.map((error, index) => (
      <div className="calculator-content-section" key={`${error}-${index + 1}`}>
        <p className="description">{error}</p>
      </div>
    ));

  return (
    <div className="calculator-widget-wrapper">
      <div className="calculator-title">ZUSAMMENFASSUNG</div>
      {(!!dataToRender.length || !!errors.length) && (
        <div className="calculator-content" data-testid="calculator-content">
          {errors.length > 0 ? errorsToRender() : dataToRender}
        </div>
      )}
      {isEmpty && <div className="calculator-content-placeholder" />}
    </div>
  );
});

export default CalculatorWidget;
