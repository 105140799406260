/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is store for photovoltaik data
 */
import { Extras, Calculator } from 'bsh-calculator';
import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { ProfitabilityCalculationForm } from '../interfaces/ICache';
import { ModuleAreaForm } from '../interfaces/IComponents';
import type RootStore from './RootStore';
import { CalculationParse, SelfSufficienceForm } from '../api/v1/parse';
import API from '../api/API';
import pages from '../const/pages';
import { getCheckboxListMenuFromSwitchers } from '../helpers/parse';

const calculator = new Calculator();

class PhotovoltaikStore {
  private rootStore: RootStore;

  private static defaultProfitabilitySection = {
    secondElectricityMeter: false,
    priceInflation: 3.5,
    forecastPeriod: 30,
  };

  private static defaultModuleArea: ModuleAreaForm = {
    id: '',
    moduleAreaLabel: '',
    moduleAreaTypes: ['default'],
    moduleAreaDirectionId: null,
  };

  private static defaultPhovoltaikExtras = {
    newCounterBox: false,
    moveSat: false,
    withoutZaehlerhelden: false,
    privatCounterBox: false,
    moveRoofSteps: false,
    fem111SmartCounter: false,
    enerPlug: false,
    drohn: false,
    birdProtection: false,
    senecHeat: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    const id = uuidv4();

    this.ModuleAreaForms.set(id, { ...PhotovoltaikStore.defaultModuleArea, id });
  }

  /**
   * additional section on basic data page
   */
  private ProfitabilityCalculationForm: ProfitabilityCalculationForm = PhotovoltaikStore.defaultProfitabilitySection;

  /**
   * Modulart section
   */
  private ModulTypeId: Nullable<string> = null;

  private StorageBrandId: Nullable<string> = null;

  /**
   * storage section on photovoltaik page
   * field parse Calculator -> storages
   */
  private StoragesId: Nullable<string> = null;

  // private InternalCalculatedStorageValue: Nullable<string> = null;

  /**
   * moduleArea forms
   */
  private ModuleAreaForms: Map<string, ModuleAreaForm> = new Map();

  private ImageRoofPlan: Nullable<string> = null;

  private RoofPlanImageFile: File | null = null;

  /**
   * extra options on photovoltaik page
   */
  private PhotovoltaikExtrasForm: Extras = PhotovoltaikStore.defaultPhovoltaikExtras;

  private SelfSufficienceForm: SelfSufficienceForm = {
    selfSufficienceValue: 30,
    selfSufficienceManually: true,
    yearlyElectricityTraffic: 6000,
    pvPerformance: 10,
    usableStorage: 6,
  };

  get selfSufficienceForm() {
    return this.SelfSufficienceForm;
  }

  get profitabilityCalculationForm() {
    return this.ProfitabilityCalculationForm;
  }

  // get internalCalculatedStorageValue() {
  //   return this.InternalCalculatedStorageValue;
  // }

  get storageBrandId() {
    return this.StorageBrandId;
  }

  get storagesId() {
    // return this.storagesId ?? this.InternalCalculatedStorageValue;
    return this.StoragesId;
  }

  // object of storage from product cache
  get storages() {
    if (this.storagesId) {
      return this.rootStore.cacheStore.getProductById(this.storagesId);
    }

    return null;
  }

  get roofPlanImageInfo() {
    return this.RoofPlanImageFile;
  }

  get modulTypeId() {
    return this.ModulTypeId;
  }

  get imageRoofPlan() {
    return this.ImageRoofPlan;
  }

  // get all moduleArea forms
  get moduleAreaForms() {
    return Array.from(this.ModuleAreaForms.values());
  }

  get photovoltaikExtrasForm() {
    return this.PhotovoltaikExtrasForm;
  }

  // readonly field from profitability section
  get electricityPrice() {
    const { powerConsumption, grossElectricityCosts } = this.ProfitabilityCalculationForm;

    return powerConsumption && grossElectricityCosts ? ((grossElectricityCosts / powerConsumption) * 100).toFixed(2) : 0;
  }

  /**
   * get summary data about module areas
   */
  get summaryModuleAreaForm() {
    const summaryNumberModules = this.moduleAreaForms.reduce((acc, form) => acc + (form.numbersOfModules ?? 0), 0);
    const pvPlannedSize = this.moduleAreaForms.reduce((acc, form) => acc + (form.moduleAreaSize ?? 0), 0);
    let moduleArea = this.moduleAreaForms.length;

    const modulesData = this.moduleAreaForms.map((form) => ({
      ...form,
      moduleAreaDirection: form.moduleAreaDirectionId ?? '',
      moduleAreaTypes: form.moduleAreaTypes ?? [],
      numbersOfModules: form.numbersOfModules ?? 0,
      moduleAreaSize: form.moduleAreaSize ?? 0,
    }));

    const averageYield = calculator.calculateAverageYield(modulesData);
    const inverterPower = calculator.calculateInverterPower(modulesData);

    const isFlatRoof = !!this.moduleAreaForms.find((form) =>
      form.moduleAreaTypes?.includes('montage_unterkonstruktion_fuer_flachdach_ost_west'));

    if (isFlatRoof) {
      moduleArea++;
    }

    return {
      summaryNumberModules,
      pvPlannedSize: Number(pvPlannedSize.toFixed(3)),
      averageYield,
      inverterPower: Number(inverterPower.toFixed(3)),
      moduleArea,
    };
  }

  setStorageBrandId(storageBrandId: Nullable<string>) {
    this.StorageBrandId = storageBrandId;
  }

  /**
   * set module type id
   * @param productId - string
   */
  setModulTypeId(productId: Nullable<string>) {
    this.ModulTypeId = productId;
  }

  setRoofPlanImageFile(image: Nullable<File>) {
    this.RoofPlanImageFile = image;
  }

  /**
   * set store id
   * @param storagesId - string - storage id
   */
  setStoragesId(storagesId: Nullable<string>) {
    this.StoragesId = storagesId;
  }

  /**
   * set photovoltaik extra options form
   * @param newForm - new form
   */
  setPhotovoltaikExtrasForm(newForm: Extras) {
    this.PhotovoltaikExtrasForm = newForm;
  }

  setSelfSufficienceForm(newForm: SelfSufficienceForm) {
    this.SelfSufficienceForm = newForm;
  }

  /**
   * set calculated storage from library
   * @param product - IProduct from crm
   */
  // setInternalCalculatedStorageValue(product: Nullable<string>) {
  //   this.InternalCalculatedStorageValue = product;
  // }

  // set profitability calculation form
  setProfitabilityCalculationForm(newForm: ProfitabilityCalculationForm) {
    this.ProfitabilityCalculationForm = newForm;
  }

  // update single moduleArea form
  setModuleAreaForm(id: string, newForm: ModuleAreaForm) {
    this.ModuleAreaForms.set(id, newForm);
  }

  setImageRoofPlan(image: Nullable<string>) {
    this.ImageRoofPlan = image;
  }

  /**
   * set SelfSufficience Value
   * @param value - new value
   */
  setSelfSufficienceValue(value: number) {
    this.SelfSufficienceForm.selfSufficienceValue = value;
  }

  /**
   * set photovoltaik store on calculation object load from parse
   * @param calculationParse - calculation object from parse
   */
  loadStore(calculationParse: CalculationParse) {
    this.ProfitabilityCalculationForm = {
      ...this.ProfitabilityCalculationForm,
      secondElectricityMeter: calculationParse.secondElectricityMeter ?? false,
      priceInflation: calculationParse.priceInflation ?? 3.5,
      forecastPeriod: calculationParse.forecastPeriod ?? 30,
      powerConsumption: calculationParse.powerConsumption,
      electricityConsumption: calculationParse.electricityConsumption,
      grossElectricityCosts: calculationParse.grossElectricityCosts,
    };

    this.ModulTypeId = calculationParse.modulType;

    this.StorageBrandId = calculationParse.storageBrandId ?? null;
    this.StoragesId = calculationParse.storages ?? null;

    // this.InternalCalculatedStorageValue = calculationParse.internalCalculatedStorageValue ?? null;

    if (calculationParse.selfSufficienceForm) {
      this.SelfSufficienceForm = calculationParse.selfSufficienceForm;
    }

    if (calculationParse.moduleAreaForms && Array.isArray(calculationParse.moduleAreaForms)) {
      const parseForms = calculationParse.moduleAreaForms as (ModuleAreaForm & any)[];

      this.ModuleAreaForms = new Map();

      parseForms.map((form) => {
        const id = uuidv4();

        this.ModuleAreaForms.set(id, {
          ...form,
          id,
          moduleAreaDirectionId: form.moduleAreaDirection,
          moduleAreaTypes: form.moduleAreaTypes ? form.moduleAreaTypes : ['default'],
        });

        return form;
      });
    }

    this.PhotovoltaikExtrasForm = {
      ...this.PhotovoltaikExtrasForm,
      newCounterBox: calculationParse.newCounterBox,
      moveSat: calculationParse.moveSat,
      withoutZaehlerhelden: calculationParse.withoutZaehlerhelden,
      privatCounterBox: calculationParse.privatCounterBox,
      privatCounterBoxNumber: calculationParse.privatCounterBoxNumber ?? 1,
      moveRoofSteps: calculationParse.moveRoofSteps,
      fem111SmartCounter: calculationParse.fem111SmartCounter,
      fem111SmartCounterNumber: calculationParse.fem111SmartCounterNumber,
      femAppPowerHeat: calculationParse.femAppPowerHeat as string,
      enerPlug: calculationParse.enerPlug,
      enerPlugNumber: calculationParse.enerPlugNumber ?? 1,
      enerPlugSetNumber: calculationParse.enerPlugSetNumber ?? 1,
      drohn: calculationParse.drohn,
    };

    this.SelfSufficienceForm.selfSufficienceValue = calculationParse.selfSufficienceValue ?? 30;
  }

  async uploadRoofPlanImage() {
    // If file is selected and image is not uploaded yet
    if (this.RoofPlanImageFile && this.ImageRoofPlan?.includes('blob:')) {
      const newImage = await API.deal.uploadPhoto(this.RoofPlanImageFile, this.rootStore.userStore.crmDealId);

      runInAction(() => {
        this.setImageRoofPlan(newImage);
        this.RoofPlanImageFile = null;
      });
    } else {
      if (!this.ImageRoofPlan) {
        API.deal.removePhoto(this.rootStore.userStore.crmDealId);

        return;
      }

      // If file is not selected but image is uploaded
      API.parse.updateImageRoofPlanPhoto(this.rootStore.userStore.crmDealId, this.ImageRoofPlan);
    }
  }

  /**
   * load photovoltaik data to calculation object in parse
   */
  async syncPhotovoltaikParseData() {
    await this.uploadRoofPlanImage();

    const objectId = this.rootStore.dataStore.calculationObjectId;

    if (!objectId) {
      return;
    }

    const moduleAreaForms = JSON.parse(JSON.stringify(this.moduleAreaForms)) as ModuleAreaForm[];

    const parsedModuleAreaForms = moduleAreaForms.map((form) => {
      const newForm: any = {
        ...form,
        moduleAreaDirection: form.moduleAreaDirectionId,
        moduleAreaTypes: form.moduleAreaTypes,
      };

      delete newForm.id;
      delete newForm.moduleAreaDirectionId;
      // TODO remove after parse drop
      delete newForm.moduleAreaType;

      return newForm;
    });

    const calculationObject: any = {
      ...this.PhotovoltaikExtrasForm,
      modulType: this.ModulTypeId,
      storages: this.storagesId,
      storageBrandId: this.StorageBrandId,
      moduleAreaForms: parsedModuleAreaForms,
      enerPlugNumber: this.PhotovoltaikExtrasForm.enerPlug ? this.PhotovoltaikExtrasForm.enerPlugNumber : undefined,
      enerPlugSetNumber: this.PhotovoltaikExtrasForm.enerPlug ? this.PhotovoltaikExtrasForm.enerPlugSetNumber : undefined,
      selfSufficienceValue: this.SelfSufficienceForm.selfSufficienceValue,
      selfSufficienceForm: this.selfSufficienceForm,
      // internalCalculatedStorageValue: this.internalCalculatedStorageValue,
      internalObjectAddress: this.rootStore.dataStore.internalObjectAddress,
      checkboxListMenu: getCheckboxListMenuFromSwitchers(this.rootStore.dataStore.formSwitchers),
      internalLastPageUrl: pages.basicData,
    };

    await API.parse.syncCalculationObject(calculationObject, objectId);
  }

  /**
   * add new empty module area form
   */
  addEmptyModuleAreaForm() {
    const id = uuidv4();

    this.ModuleAreaForms.set(id, { ...PhotovoltaikStore.defaultModuleArea, id });
  }

  /**
   * remove module area form
   * @param id - module area form id
   */
  removeModuleArea(id: string) {
    this.ModuleAreaForms.delete(id);
  }

  /**
   * reset photovoltaik store
   */
  resetStore() {
    this.ProfitabilityCalculationForm = PhotovoltaikStore.defaultProfitabilitySection;
    this.ModulTypeId = null;
    this.ModuleAreaForms = new Map();
    this.PhotovoltaikExtrasForm = PhotovoltaikStore.defaultPhovoltaikExtras;

    const id = uuidv4();

    this.ModuleAreaForms.set(id, { ...PhotovoltaikStore.defaultModuleArea, id });
  }
}

export default PhotovoltaikStore;
