/**
 * Created by Ilya Savinov 07/02/24
 *

 * this module area section component
 *
 * props:
 * @moduleForm - ModuleAreaForm - form properties
 * @setModuleForm - (newModuleForm: ModuleAreaForm) => void - module area form setter
 * @onCloseClick - () => void - handle close click
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';

import InputText, { InputRefType } from '../forms/InputText';
import Select from '../forms/Select';
import config from '../../config';
import { ModuleAreaForm, SelectOption } from '../../interfaces/IComponents';
import useStore from '../../hooks/useStore';
import crossIcon from '../../assets/icons/cross.svg';
import i18n from '../../i18n';
import standardRoof from '../../assets/images/standardRoof.jpg';
import Tooltip from '../elements/Tooltip';
import Validation from '../../lib/Validation';
import ImageButton from '../elements/ImageButton';

interface Props {
  moduleForm: ModuleAreaForm;
  setModuleForm: (newForm: ModuleAreaForm) => void;
  onCloseClick?: () => void;
  validation?: { [key in string]: string };
  index: number;
}

const ModuleAreaSection: React.FC<Props> = observer(({ moduleForm, setModuleForm, onCloseClick, index }) => {
  const { cacheStore, photovoltaikStore } = useStore();
  const { modulTypeId, storagesId } = photovoltaikStore;
  const { moduleAreaLabel, numbersOfModules, moduleAreaProduction, moduleAreaTypes, moduleAreaDirectionId, moduleAreaSize } = moduleForm;
  const { calculator } = cacheStore;

  // Docs 2.3.29
  // eslint-disable-next-line max-len
  // https://wiki.go-digital-software.de/books/bsh-4386-02-handbook-bsh-quote-calculator-20-bsh-4386/page/2-photovoltaik-quote-photovoltaikquote
  const [numberOfModulesTooltip, setNumberOfModulesTooltip] = useState(false);

  const [productionLimits, setProductionLimits] = useState<Nullable<{ min: number; max: number }>>(
    config.moduleAreaProductionLimits[moduleAreaDirectionId ?? ''],
  );

  const moduleAreaLabelRef = useRef<InputRefType>(null);
  const moduleAreaProdRef = useRef<InputRefType>(null);
  const moduleAreaSizeRef = useRef<InputRefType>(null);
  const numberOfModulesRef = useRef<InputRefType>(null);
  const moduleAreaDirectionRef = useRef<InputRefType>(null);

  // module type options
  const moduleTypeOptions = useMemo(() => {
    const types =
      cacheStore.getProductsByGroupId('roofType')?.map((roof) => ({
        ...roof,
        name: roof.name.replace('Montage ', '').replace('Unterkonstruktion für ', ''),
      })) ?? [];

    return [{ id: 'default', serviceId: 'default', name: t('moduleAreaTypes.standardRoof'), image: standardRoof }, ...types];
  }, []);

  // const moduleAreaType = moduleTypeOptions.find((type) => moduleAreaTypeId.includes(type.id));

  // direction options
  const directionOptions = useMemo(() => {
    if (moduleAreaTypes.length === 0) {
      return config.directions;
    }

    if (moduleAreaTypes.includes('montage_unterkonstruktion_fuer_flachdach_ost_west')) {
      const westEast = config.directions.find((dir) => dir.id === 'west_east');

      return westEast ? [westEast] : [];
    }

    return config.directions.filter((dir) => dir.id !== 'west_east');
  }, [moduleAreaTypes]);

  const moduleAreaDirection = directionOptions.find((dir) => dir.id === moduleAreaDirectionId);

  const validateForm = useCallback(() => {
    let formValidated = true;

    const refs: [InputRefType | null, string?][] = [
      [moduleAreaLabelRef.current, moduleAreaLabel],
      [moduleAreaProdRef.current, moduleAreaProduction?.toString()],
      [moduleAreaSizeRef.current, moduleAreaSize?.toString()],
      [numberOfModulesRef.current, numbersOfModules?.toString()],
      [moduleAreaDirectionRef.current, moduleAreaDirection?.id.toString()],
    ];

    refs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text ?? '');

        if (!validObj.validated) {
          refValue.setValidation(validObj);
          formValidated = false;
        }
      }

      return refValue;
    });

    return formValidated;
  }, [moduleAreaLabel, moduleAreaProduction, moduleAreaSize, numbersOfModules, moduleAreaDirection]);

  const getFormValidation = useCallback(() => {
    let formValidated = true;

    const refs: [InputRefType | null, string?][] = [
      [moduleAreaLabelRef.current, moduleAreaLabel],
      [moduleAreaProdRef.current, moduleAreaProduction?.toString()],
      [moduleAreaSizeRef.current, moduleAreaSize?.toString()],
      [numberOfModulesRef.current, numbersOfModules?.toString()],
      [moduleAreaDirectionRef.current, moduleAreaDirection?.id.toString()],
    ];

    refs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text ?? '');

        if (!validObj.validated) {
          formValidated = false;
        }
      }

      return refValue;
    });

    if (!moduleAreaTypes.length) {
      formValidated = false;
    }

    return formValidated;
  }, [moduleAreaLabel, moduleAreaProduction, moduleAreaSize, numbersOfModules, moduleAreaDirection, moduleAreaTypes]);

  useEffect(() => {
    setModuleForm({ ...moduleForm, validateForm, getFormValidation });
  }, [validateForm, getFormValidation]);

  useEffect(() => {
    validateForm?.();
  }, [validateForm]);

  useEffect(() => {
    const newModuleForm: any = moduleForm;
    const modulType = cacheStore.getProductById(modulTypeId ?? '', '', true);

    const validateObj = calculator.validateNumberOfModules({
      modulType: modulTypeId ?? undefined,
      storages: storagesId,
      numbersOfModules: moduleForm.numbersOfModules ?? 0,
      moduleAreaTypes: newModuleForm.moduleAreaTypes ?? [],
    });

    let newModuleAreaSize = Number(moduleForm.moduleAreaSize);

    if (modulType) {
      newModuleAreaSize = calculator.calculateModuleAreaSize(validateObj.defaultValue ?? moduleForm.numbersOfModules ?? 0, modulType);
    }

    newModuleForm.numbersOfModules = validateObj.defaultValue ?? numbersOfModules;
    newModuleForm.moduleAreaSize = newModuleAreaSize;

    setModuleForm(newModuleForm);
  }, [modulTypeId]);

  // production validation
  const moduleAreaProductionValidation = (v: string) => {
    const number = Number(v);

    if (!number || number === 0) {
      return { validated: false, msg: i18n.t('validations.fieldRequired') };
    }

    return { validated: true, msg: '' };
  };

  /**
   * hint module area production limits
   * @param limits - production limits for all directions
   */
  const hintProductionLimits = (limits = productionLimits) => {
    if (limits) {
      const { min, max } = limits;

      return i18n.t('validations.validationModuleProduction', { min, max });
    }

    return '';
  };

  // handle moduleType selection (Docs 2.3.1-2.3.2)
  const handleModuleTypeSelection = (optId: string) => {
    const isIncludes = moduleAreaTypes.includes(optId);
    const flatRoofValidation = config.moduleAreaProductionLimits.west_east;
    const modulType = cacheStore.getProductById(modulTypeId ?? '', '', true);

    const newModuleForm: any = moduleForm;

    if (isIncludes) {
      newModuleForm.moduleAreaTypes = moduleAreaTypes.filter((id) => id !== optId);
    } else {
      newModuleForm.moduleAreaTypes = [...moduleAreaTypes, optId as string];
    }

    const validateObj = calculator.validateNumberOfModules({
      modulType: modulTypeId ?? undefined,
      storages: storagesId,
      numbersOfModules: moduleForm.numbersOfModules ?? 0,
      moduleAreaTypes: newModuleForm.moduleAreaTypes ?? [],
    });

    let newModuleAreaSize = Number(moduleForm.moduleAreaSize);

    if (modulType) {
      newModuleAreaSize = calculator.calculateModuleAreaSize(validateObj.defaultValue ?? moduleForm.numbersOfModules ?? 0, modulType);
    }

    newModuleForm.numbersOfModules = validateObj.defaultValue ?? numbersOfModules;
    newModuleForm.moduleAreaSize = newModuleAreaSize;

    const isFlatIncludes = newModuleForm.moduleAreaTypes.includes('montage_unterkonstruktion_fuer_flachdach_ost_west');

    // moduleAreaType: Flachdach Aufständerung
    if (!isFlatIncludes) {
      newModuleForm.moduleAreaDirectionId = moduleAreaDirection?.id === 'west_east' ? null : moduleForm.moduleAreaDirectionId;
    } else if (optId === 'montage_unterkonstruktion_fuer_flachdach_ost_west') {
      setProductionLimits(flatRoofValidation);
      newModuleForm.moduleAreaDirectionId = 'west_east';
      newModuleForm.moduleAreaProduction = undefined;
      moduleAreaDirectionRef.current?.setValidation({ validated: true, msg: '' });
    }

    setModuleForm(newModuleForm);
  };

  // handle direction change
  const handleDirectionChange = (opt: SelectOption) => {
    const newProductionLimits = config.moduleAreaProductionLimits[opt.id];
    // let prodValue = moduleAreaProduction;

    setProductionLimits(newProductionLimits);

    // if (prodValue && newProductionLimits && prodValue < newProductionLimits.min) {
    //   prodValue = newProductionLimits.min;
    // }

    // if (prodValue && newProductionLimits && prodValue > newProductionLimits.max) {
    //   prodValue = newProductionLimits.max;
    // }

    const validateObj = moduleAreaProductionValidation(moduleAreaProduction?.toString() ?? '0');

    moduleAreaProdRef.current?.setValidation(validateObj);

    setModuleForm({ ...moduleForm, moduleAreaDirectionId: opt.id.toString(), moduleAreaProduction: undefined });
  };

  // handle area production input blur
  const handleProductionBlur = () => {
    if (productionLimits) {
      const { min, max } = productionLimits;

      const production = moduleForm.moduleAreaProduction ?? 0;

      if (min && production < min) {
        setModuleForm({ ...moduleForm, moduleAreaProduction: min });
        moduleAreaProdRef.current?.setValidation({ validated: true, msg: '' });
      }

      if (max && production > max) {
        setModuleForm({ ...moduleForm, moduleAreaProduction: max });
        moduleAreaProdRef.current?.setValidation({ validated: true, msg: '' });
      }
    }
  };

  // handle Area production input change
  const handleAreaProduction = (v: string) => {
    const newValue = v ? Number(parseFloat(v).toFixed(3)) : undefined;

    setModuleForm({ ...moduleForm, moduleAreaProduction: newValue });
  };

  // handle number of modules field change
  const handleNumberOfModulesChange = (v: string) => {
    const newValue = v ? parseInt(v, 10) : undefined;

    setModuleForm({ ...moduleForm, numbersOfModules: newValue });
  };

  // handle number of modules input blur
  const handleNumberOfModulesBlur = () => {
    const validateObj = calculator.validateNumberOfModules({
      modulType: modulTypeId ?? undefined,
      storages: storagesId,
      numbersOfModules: moduleForm.numbersOfModules ?? 0,
      moduleAreaTypes: moduleAreaTypes ?? [],
    });

    let newModuleAreaSize = Number(moduleForm.moduleAreaSize);

    const modulType = cacheStore.getProductById(modulTypeId ?? '', '', true);

    if (modulType) {
      newModuleAreaSize = calculator.calculateModuleAreaSize(validateObj.defaultValue ?? moduleForm.numbersOfModules ?? 0, modulType);
    }

    setModuleForm({
      ...moduleForm,
      numbersOfModules: validateObj.defaultValue ?? numbersOfModules,
      moduleAreaSize: newModuleAreaSize,
    });

    moduleAreaSizeRef?.current?.setValidation({ validated: true, msg: '' });
    numberOfModulesRef?.current?.setValidation({ validated: true, msg: '' });
  };

  // validate number of modules field
  const validateNumberOfModules = (p: string) => {
    const newNumbersOfModules = parseInt(p, 10);

    if (!newNumbersOfModules || newNumbersOfModules === 0) {
      return { validated: false, msg: i18n.t('validations.fieldRequired') };
    }

    return { validated: true, msg: '' };
  };

  /**
   * show highlights for input
   */
  const hintNumberOfModules = () => {
    const validationProps = {
      modulType: modulTypeId ?? undefined,
      storages: storagesId,
      numbersOfModules: numbersOfModules ?? 0,
      moduleAreaTypes: moduleAreaTypes ?? [],
    };

    const validateObj = calculator.validateNumberOfModules(validationProps);

    return validateObj.errorMessage ?? '';
  };

  // handle module area size input change
  const handleModuleAreaSizeChange = (v: string) => {
    const newValue = v ? Number(parseFloat(v).toFixed(3)) : undefined;

    setModuleForm({ ...moduleForm, moduleAreaSize: newValue });
  };

  // handle area size input blur
  const handleAreaSizeBlur = () => {
    let newNumberOfModules = moduleForm.numbersOfModules;
    let recalculatedAreaSize = Number(moduleForm.moduleAreaSize) ?? 0;

    const modulType = cacheStore.getProductById(modulTypeId ?? '', '', true);

    if (modulType) {
      newNumberOfModules = calculator.calculateNumberOfModules(recalculatedAreaSize, modulType);

      const validateObj = calculator.validateNumberOfModules({
        modulType: modulTypeId ?? undefined,
        storages: storagesId,
        numbersOfModules: newNumberOfModules ?? 0,
        moduleAreaTypes: moduleAreaTypes ?? [],
      });

      if (!validateObj.isValid) {
        newNumberOfModules = validateObj.defaultValue || 0;
      }

      numberOfModulesRef.current?.setValidation({ validated: true, msg: '' });

      recalculatedAreaSize = calculator.calculateModuleAreaSize(newNumberOfModules, modulType);
    }

    setModuleForm({ ...moduleForm, numbersOfModules: newNumberOfModules, moduleAreaSize: recalculatedAreaSize });
  };

  const emptyValidateFunction = (p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'));

  return (
    <div className="surface-wrapper" data-testid={`module-area-${index}`}>
      <div className="cross-icon" onClick={onCloseClick}>
        <img src={crossIcon} alt="cross" />
      </div>
      <div className="surface-type-wrapper" data-testid="module-area-types-wrapper">
        {moduleTypeOptions.map((option) => (
          <ImageButton
            key={option.id}
            label={option.name}
            imageSrc={option.image ?? undefined}
            isActive={moduleAreaTypes.includes(option.serviceId)}
            onClick={() => handleModuleTypeSelection(option.serviceId)}
            isSmall
            className="surface-type"
          />
        ))}
      </div>
      <div className="surface-input-row">
        <InputText
          ref={moduleAreaLabelRef}
          className="surface-input"
          value={moduleAreaLabel}
          onChange={(v) => setModuleForm({ ...moduleForm, moduleAreaLabel: v as string })}
          title={i18n.t('photovoltaikPage.description')}
          validateFunc={emptyValidateFunction}
        />
        <Select
          ref={moduleAreaDirectionRef}
          wrapperClassName="surface-input"
          title={i18n.t('photovoltaikPage.orientation')}
          options={directionOptions}
          value={moduleAreaDirection}
          onSuggestionSelected={handleDirectionChange}
          placeholder={i18n.t('photovoltaikPage.noSelection')}
          validateFunc={emptyValidateFunction}
          testId={`module-area-direction-${index}`}
        />
        <InputText
          ref={moduleAreaProdRef}
          className="surface-input"
          title={i18n.t('photovoltaikPage.moduleAreaProduction')}
          suffix="kWh/Jahr"
          type="number"
          value={moduleAreaProduction}
          validateFunc={(p) => moduleAreaProductionValidation(p)}
          hintOnFocus={hintProductionLimits(productionLimits)}
          onChange={handleAreaProduction}
          onBlur={handleProductionBlur}
          testId={`module-area-production-${index}`}
        />
      </div>
      <div className="surface-input-row">
        <Tooltip text={i18n.t('photovoltaikPage.minimumNumberOfModules')} isShow={numberOfModulesTooltip} className="surface-input">
          <InputText
            ref={numberOfModulesRef}
            className="tooltip-input"
            type="number"
            min={0}
            title={i18n.t('photovoltaikPage.numberOfModules')}
            value={numbersOfModules}
            onChange={handleNumberOfModulesChange}
            validateFunc={validateNumberOfModules}
            hintOnFocus={hintNumberOfModules()}
            onMouseEnter={() => setNumberOfModulesTooltip(true)}
            onMouseLeave={() => setNumberOfModulesTooltip(false)}
            onBlur={handleNumberOfModulesBlur}
            testId={`module-area-number-modules-${index}`}
          />
        </Tooltip>
        <InputText
          ref={moduleAreaSizeRef}
          className="surface-input"
          title={i18n.t('photovoltaikPage.areaSize')}
          suffix="kWp"
          type="number"
          value={moduleAreaSize}
          validateFunc={emptyValidateFunction}
          onChange={handleModuleAreaSizeChange}
          onBlur={handleAreaSizeBlur}
          testId={`module-area-size-${index}`}
        />
      </div>
    </div>
  );
});

export default ModuleAreaSection;
